import React from 'react';
import * as styles from './VCButton.module.scss';
import { ComponentProps } from './VCButton.model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/**
 * The basic button component used for href.
 * @param {*} props
 * @returns A button component
 */
export const VCButton = ({
  className,
  icon = ['fab', 'figma'],
  link,
  onClick = () => {},
  label,
}: ComponentProps) => {
  const isExternal = link.match(/^\//gi) ? false : true;

  return (
    <a
      className={`${styles.mainWrapper} vc-txts-link ${className} ${
        isExternal ? styles.external : ''
      }`}
      href={link}
      onClick={onClick}
      title={label}
      target={isExternal ? '_blank' : '_self'}
      aria-label={label}
    >
      <FontAwesomeIcon icon={icon} />
    </a>
  );
};
