import React from 'react';
import { VCButton } from '../VCButton';
import * as styles from './VCFooter.module.scss';

/**
 * The footer component
 */
export const VCFooter = () => {
  return (
    <footer className={styles.mainWrapper}>
      <p>
        Site designed and{' '}
        <a href="https://gitlab.com/VirtualClover/guillermo-lopez-portfolio">
          coded
        </a>{' '}
        by yours truly. ❤
      </p>
      <ul>
        <li>
          <VCButton
            icon={'envelope-square'}
            label={'Send me a mail!'}
            link={'mailto:memo@virtualclover.ltd'}
          />
        </li>
        <li>
          <VCButton
            icon={['fab', 'github']}
            label={'Check my git repos.'}
            link={'https://gitlab.com/VirtualClover'}
          />
        </li>
        <li>
          <VCButton
            icon={['fab', 'linkedin']}
            label={'Go to my linkedIn profile.'}
            link={'https://www.linkedin.com/in/vc-memo-lopez/'}
          />
        </li>
        <li>
          <VCButton
            icon={'brush'}
            label={'Got to my illustration portfolio.'}
            link={'https://virtualclover.tumblr.com/'}
          />
        </li>
      </ul>
    </footer>
  );
};
