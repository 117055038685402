import React from 'react';
import * as styles from './VCImg.module.scss';
import { ComponentProps } from './VCImg.model';

/**
 * The basic component used for images
 */
export const VCImg = ({
  className,
  webpSrc,
  jpegSrc,
  alt = 'An image',
  loading = 'eager',
}: ComponentProps) => {
  return (
    <div className={`${styles.mainWrapper} ${className}`}>
      <img
        srcSet={`${webpSrc} 1x, ${jpegSrc} 1x`}
        alt={alt}
        loading={loading}
      />
      <figcaption aria-hidden>{alt}</figcaption>
    </div>
  );
};
