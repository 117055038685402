import mijo1 from '../../images/projects/MIJO/preview/1.jpg';
import mijo2 from '../../images/projects/MIJO/preview/2.jpg';
import mijo3 from '../../images/projects/MIJO/preview/3.jpg';
import mijo1w from '../../images/projects/MIJO/preview/1.webp';
import mijo2w from '../../images/projects/MIJO/preview/2.webp';
import mijo3w from '../../images/projects/MIJO/preview/3.webp';

import flare1 from '../../images/projects/Flare/preview/1.jpg';
import flare2 from '../../images/projects/Flare/preview/2.jpg';
import flare3 from '../../images/projects/Flare/preview/3.jpg';
import flare1w from '../../images/projects/Flare/preview/1.webp';
import flare2w from '../../images/projects/Flare/preview/2.webp';
import flare3w from '../../images/projects/Flare/preview/3.webp';

import rx1 from '../../images/projects/RX/preview/1.jpg';
import rx2 from '../../images/projects/RX/preview/2.jpg';
import rx3 from '../../images/projects/RX/preview/3.jpg';
import rx1w from '../../images/projects/RX/preview/1.webp';
import rx2w from '../../images/projects/RX/preview/2.webp';
import rx3w from '../../images/projects/RX/preview/3.webp';

import illusts1 from '../../images/projects/Illusts/preview/1.jpg';
import illusts2 from '../../images/projects/Illusts/preview/2.jpg';
import illusts3 from '../../images/projects/Illusts/preview/3.jpg';
import illusts1w from '../../images/projects/Illusts/preview/1.webp';
import illusts2w from '../../images/projects/Illusts/preview/2.webp';
import illusts3w from '../../images/projects/Illusts/preview/3.webp';

export const listItems = [
  {
    projectTitle: 'Flare',
    type: 'Project',
    position: 'UX/UI design & web/APP development',
    year: 2022,
    link: '/projects/Flare',
    figmaLink:
      'https://www.figma.com/file/tME6tH8PqFoRQZe1gd16hG/Flare-Public?node-id=1%3A3&t=5hRL9u4WUdJzg9ms-1',
    gitLink: 'https://github.com/VirtualClover/Moon-System',
    images: [
      { jpg: flare1, webp: flare1w, alt: 'FLARE LOGO' },
      { jpg: flare2, webp: flare2w, alt: `FLARE ILLUSTRATIONS` },
      {
        jpg: flare3,
        webp: flare3w,
        alt: `USING FLARE IN DIFFERENT SRCEENS`,
        main: true,
      },
    ],
    description: `Bitso's design system.`,
  },
  {
    projectTitle: 'Mijo DS',
    type: 'Project',
    position: 'UX/UI design & web/APP development',
    year: 2021,
    link: '/projects/MIJO',
    figmaLink:
      'https://www.figma.com/file/HP4IPEcsEzBt9O6fVzXL3U/MIJO-Master?t=4CvOi2HhNZvfCPHo-6',
    gitLink: 'https://gitlab.com/VirtualClover/mijo',
    images: [
      { jpg: mijo1, webp: mijo1w, alt: 'Mijo DS' },
      { jpg: mijo2, webp: mijo2w, alt: `Mijo's main palette`, main: true },
      { jpg: mijo3, webp: mijo3w, alt: `Mijo's icons` },
    ],
    description: 'A design system I made for Canasta Rosa.',
  },
  {
    projectTitle: 'RX Watches',
    type: 'Project',
    position: 'UX/UI design & web development',
    year: 2019,
    link: '/projects/RXWatches',
    gitLink: 'https://gitlab.com/VirtualClover/RXWatches',
    images: [
      { jpg: rx1, webp: rx1w, alt: `RX'S COVER`, main: true },
      { jpg: rx2, webp: rx2w, alt: `RX WATCHES` },
      { jpg: rx3, webp: rx3w, alt: `RX'S ICONS` },
    ],
    description: 'RX is a demo of a watches landing page.',
  },
  {
    projectTitle: 'Illustrations',
    type: 'Project',
    position: 'Illustration',
    year: 2019,
    link: 'https://virtualclover.tumblr.com/',
    images: [
      { jpg: illusts1, webp: illusts1w, alt: `Illustration 1`, main: true },
      { jpg: illusts2, webp: illusts2w, alt: `Illustration 2` },
      { jpg: illusts3, webp: illusts3w, alt: `Illustration 3` },
    ],
    description: 'I also do doodles from time to time.',
  },
];
