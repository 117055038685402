import React from 'react';
import * as styles from './VCNavBar.module.scss';
import { ComponentProps } from './VCNavBar.model';
import { VCButton } from '../VCButton';

const HomeConfig = () => (
  <ul>
    <li>
      <VCButton link="files/CV.pdf" icon="user" label="Check my cv" />
    </li>
  </ul>
);

const ProjectConfig = () => (
  <ul>
    <li>
      <VCButton link="/" icon="briefcase" label="Go to my portfolio" />
    </li>
    <li>
      <VCButton link="files/CV.pdf" icon="user" label="Check my cv" />
    </li>
  </ul>
);

/**
 * The navigation bar component
 */
export const VCNavBar = ({ configuration }: ComponentProps) => {
  return (
    <nav className={`${styles.mainWrapper}`}>
      <div />
      {configuration == 'home' ? <HomeConfig /> : <ProjectConfig />}
    </nav>
  );
};
